<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcChurchLists }}</h1>
    </header>
    <!-- / Page Header -->

    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTooltip" :i18n="translations.components">
      </camp-selector>
      <!-- / Camp Selector -->

      <data-table :fields="dataTableFields" :items="filteredChurches" :addItems="addItems"
        :includeAddButton="iCanSee(secured_church_details_add_controls.add_church_details_button)"
        @addChurch="addChurch" @churchDisplayTypeChange="handleDisplayTypeChange($event)" :searchTerm="`churchTerm`"
        :csvUrl="churchListReportAsCSVUrl" :pdfUrl="churchListReportAsPDFUrl" :reportName="reportName"
        :i18n="translations.components"></data-table>
      <!-- / Data Tables -->
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-list',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      dataTableFields: [],
      addItems: {
        display: true,
        text: 'Add Church',
        action: 'addChurch',
      },
      secured_church_details_add_controls: {
        add_church_details_button: '9f2d194c-d174-4224-ba18-16009d56bf1e',
      },
      reportName: 'Churches',
      churchFilter: [],
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getTranslations(),
        this.getComponentTranslations('camp-select', 'data-table', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        this.setLoadingStatus(false)
      }, 2000)
    }
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadChurches: 'churchMinistry/loadChurches',
      loadChurchListReportUrls: 'churchMinistry/loadChurchListReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.setSelectedChurchKey(''),
          await this.loadChurches(),
          await this.loadChurchListReportUrls({
            hidden: this.churchFilter.includes('hidden'),
            ineligible: this.churchFilter.includes('ineligible'),
          }),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addChurch() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setSelectedChurchKey(''),
          await this.loadAddressFormOptions(),
        ]).then(() => {
          this.$router.push('/programs/cm/churchProfile/add-church')
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleDisplayTypeChange(evt) {
      this.churchFilter = evt
      await this.loadChurchListReportUrls({
        hidden: this.churchFilter.includes('hidden'),
        ineligible: this.churchFilter.includes('ineligible'),
      })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddChurch
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        {
          key: 'church_name',
          label: `${this.translations.tcChurchName}`,
          sortable: true,
        },
        { key: 'city', label: `${this.translations.tcCity}`, sortable: true },
        {
          key: 'denomination',
          label: `${this.translations.tcDenomination}`,
          sortable: true,
        },
        {
          key: 'gideon_church_rep_sort',
          label: `${this.translations.tcGideonRep}`,
          sortable: true,
        },
        {
          key: 'camp_assigned_number',
          label: `${this.translations.tcCampAssigned}`,
          sortable: true,
        },
        {
          key: 'last_presentation_date',
          label: `${this.translations.tcLastPresentation}`,
          sortable: true,
          formatter: (value, key, item) => {
            let fdate = new Date(value)
            return !!fdate & (fdate.getFullYear() > 1900) ? fdate.toISOString().substr(0, 10) : ''
          },
        },
      ]
    },
  },
  computed: {
    ...mapGetters({
      churches: 'churchMinistry/churches',
      churchListReportAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      churchListReportAsPDFUrl: 'churchMinistry/datalist1PDFUrl',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return true
    },
    filteredChurches() {
      // _rowVariant controls the row color and distinguishes hidden from ineligible
      if (this.churchFilter.includes('hidden') && !this.churchFilter.includes('ineligible')) {
        return this.churches
          .filter((kf) => kf.hidden === true)
          .map((ek) => {
            return ek.hidden === true ? { ...ek, _rowVariant: 'success' } : ek
          })
      }
      if (!this.churchFilter.includes('hidden') && this.churchFilter.includes('ineligible')) {
        return this.churches
          .filter((kf) => kf.eligible === false)
          .map((ek) => {
            return ek.eligible === false ? { ...ek, _rowVariant: 'info' } : ek
          })
      }
      if (this.churchFilter.includes('hidden') && this.churchFilter.includes('ineligible')) {
        return this.churches
          .filter((kf) => kf.hidden === true || kf.eligible === false)
          .map((ek) => {
            return ek.hidden === true
              ? { ...ek, _rowVariant: 'success' }
              : ek.eligible === false
                ? { ...ek, _rowVariant: 'info' }
                : ek
          })
      }
      return this.churches.filter((kf) => kf.hidden === false && kf.eligible === true)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
